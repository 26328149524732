import { API } from "aws-amplify";
export const GetAllDailyLogsWithDate = {
  data() {
    return {
      GetAllDailyLogsWithDateObject: [],
    };
  },
  methods: {
    async GetAllDailyLogsWithDateMethod(asset, date) {
      var self = this;
      self.overlay = true;
      const path = "getdailylogsoforganizationwithdate";
      const myInit = {
        body: {
          organization_id: asset.organization_id,
          asset_id: asset.asset_id,
          date: date,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          self.overlay = false;
          if (!response.errorType) {
            self.GetAllDailyLogsWithDateObject = response.data;
          } else {
            self.GetAllDailyLogsWithDateObject = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          self.GetAllDailyLogsWithDateObject = [];
          self.overlay = false;
          console.warn(error);
        });
    },
  },
};
