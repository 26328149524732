var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"heading1 PrimaryFontColor"},[_vm._v("Daily Logs")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-select',{staticClass:"field_height field_label_size FontSize maxWidth mt-6 mr-2",attrs:{"dense":"","outlined":"","items":_vm.plantTypeItems,"label":"Plant Type"},model:{value:(_vm.plant_type),callback:function ($$v) {_vm.plant_type=$$v},expression:"plant_type"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"field_height field_label_size FontSize maxWidth mt-6",staticStyle:{"max-width":"200px"},attrs:{"dense":"","outlined":"","label":"Select Date","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.datePickerVmodel),callback:function ($$v) {_vm.datePickerVmodel=$$v},expression:"datePickerVmodel"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePickerMenu),callback:function ($$v) {_vm.datePickerMenu=$$v},expression:"datePickerMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datePickerMenu = false}},model:{value:(_vm.datePickerVmodel),callback:function ($$v) {_vm.datePickerVmodel=$$v},expression:"datePickerVmodel"}})],1)],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-card',{attrs:{"outlined":""}},[(_vm.GetAllDailyLogsWithDateObject[_vm.plant_type] != undefined)?_c('v-row',{staticClass:"ma-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.plant_type)+" Water Produced (M³) : ")]),_vm._v(_vm._s(_vm.GetAllDailyLogsWithDateObject[((_vm.plant_type.toLowerCase()) + "_water_produced")].toFixed(2)))]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.plant_type)+" Total Hours Of Operation : ")]),_vm._v(" "+_vm._s(_vm.convertTimeToHRMinSec( _vm.GetAllDailyLogsWithDateObject[((_vm.plant_type.toLowerCase()) + "_total_hours_of_operation")] ))+" ")])],1):_vm._e()],1),_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.dailylogsHeaders,"items":_vm.GetAllDailyLogsWithDateObject[_vm.plant_type]},scopedSlots:_vm._u([{key:"item.sl_no",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(_vm.GetAllDailyLogsWithDateObject[_vm.plant_type].indexOf(item) + 1)+" ")])]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(new Date(item.start * 1000).toLocaleString("en-In").split(",")[1])+" ")])]}},{key:"item.stop",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(new Date(item.stop * 1000).toLocaleString("en-In").split(",")[1])+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }