<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Daily Logs</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              dense
              outlined
              :items="plantTypeItems"
              class="field_height field_label_size FontSize maxWidth mt-6 mr-2"
              label="Plant Type"
              v-model="plant_type"
            ></v-select>
            <v-menu
              v-model="datePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="datePickerVmodel"
                  label="Select Date"
                  prepend-inner-icon="mdi-calendar"
                  class="field_height field_label_size FontSize maxWidth mt-6"
                  style="max-width: 200px"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="datePickerVmodel" @input="datePickerMenu = false"></v-date-picker>
            </v-menu>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-card outlined>
          <v-row no-gutters class="ma-3" v-if="GetAllDailyLogsWithDateObject[plant_type] != undefined">
            <v-col cols="12" xs="12" sm="12" md="12">
              <span class="font-weight-bold">{{ plant_type }} Water Produced (M³) : </span
              >{{ GetAllDailyLogsWithDateObject[`${plant_type.toLowerCase()}_water_produced`].toFixed(2) }}</v-col
            >
            <v-col cols="12" xs="12" sm="12" md="12">
              <span class="font-weight-bold"> {{ plant_type }} Total Hours Of Operation : </span>
              {{
                convertTimeToHRMinSec(
                  GetAllDailyLogsWithDateObject[`${plant_type.toLowerCase()}_total_hours_of_operation`]
                )
              }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mt-3">
          <v-data-table
            dense
            class="elevation-0"
            :headers="dailylogsHeaders"
            :items="GetAllDailyLogsWithDateObject[plant_type]"
          >
            <template v-slot:[`item.sl_no`]="{ item }">
              <div class="fontSize">
                {{ GetAllDailyLogsWithDateObject[plant_type].indexOf(item) + 1 }}
              </div>
            </template>
            <template v-slot:[`item.start`]="{ item }">
              <div class="fontSize">
                {{ new Date(item.start * 1000).toLocaleString("en-In").split(",")[1] }}
              </div>
            </template>
            <template v-slot:[`item.stop`]="{ item }">
              <div class="fontSize">
                {{ new Date(item.stop * 1000).toLocaleString("en-In").split(",")[1] }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllDailyLogsWithDate } from "@/mixins/GetAllDailyLogsWithDate.js";
export default {
  mixins: [GetAllDailyLogsWithDate],
  components: {
    Overlay,
  },
  data: () => ({
    overlay: false,
    datePickerMenu: false,
    datePickerVmodel: new Date().toISOString().substr(0, 10),
    plant_type: "UF",
    plantTypeItems: ["UF", "RO1", "RO2"],
    dailylogsHeaders: [
      { text: "Sl No.", value: "sl_no" },
      { text: "Start", value: "start" },
      { text: "Stop", value: "stop" },
    ],
  }),
  watch: {
    datePickerVmodel(val) {
      if (val && val != "") {
        this.GetAllDailyLogsWithDateMethod(this.$store.getters.get_asset_object, this.datePickerVmodel);
      }
    },
  },
  mounted() {
    this.GetAllDailyLogsWithDateMethod(this.$store.getters.get_asset_object, this.datePickerVmodel);
  },
  methods: {
    convertTimeToHRMinSec(time) {
      let value = time * 1000;
      this.hour = Math.floor(value / 1000 / 60 / 60);
      value -= this.hour * 1000 * 60 * 60;
      this.minute = Math.floor(value / 1000 / 60);
      value -= this.minute * 1000 * 60;
      this.seconds = Math.floor(value / 1000);
      return this.addZero(this.hour) + ":" + this.addZero(this.minute) + ":" + this.addZero(this.seconds);
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
  },
};
</script>
